export function errorToMessage(error, fallbackText = null) {
  try {
    if (error.data.non_field_errors) {
      return error.data.non_field_errors[0];
    } else if (error.data.message) {
      return error.data.message;
    } else if (error.data.detail) {
      return error.data.detail;
    } else {
      if (Array.isArray(error.data)) {
        return error.data[0];
      } else {
        if (error.data.length > 300) {
          return fallbackText;
        } else {
          return error.data;
        }
      }
    }
  } catch (e) {
    return fallbackText;
  }
}

export function scrollToFirstFormError() {
  document
    ?.querySelector('.form-control.is-invalid')
    ?.scrollIntoView({ behavior: 'smooth', block: 'center' })
}